import React from 'react'
import Layout from '../components/layout'
import PoliticaPrivacidadeText from '../components/PoliticaPrivacidadeText'
import Header from '../components/ExternalPagesHeader'
import SEO from '../components/seo'
import { Box } from '@chakra-ui/core'

const PoliticaPage = () => {
	return (
		<Layout>
			<Header />
			<SEO title='Política de Privacidade' />
			<Box p={8} color='gray.700' lineHeight='1.75rem'>
				<PoliticaPrivacidadeText />
			</Box>
		</Layout>
	)
}

export default PoliticaPage
